import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {TranslocoPipe} from '@jsverse/transloco';
import {NgClass, NgFor} from '@angular/common';
import {ToggleDirective} from '@matchsource/shared/toggle';

interface SelectOption {
  label: string;
  value: string;
  values?: string[];
  params: Record<string, unknown>;
  disabled: boolean;
}

type OrientationDirection = 'left' | 'right';

type Orientation = OrientationDirection | 'auto';

const DEFAULT_ORIENTATION: OrientationDirection = 'right';

const ORIENTATION_TRAVERSE: OrientationDirection[] = ['left', 'right'];

@Component({
  selector: 'ms-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ToggleDirective, NgClass, NgFor, TranslocoPipe],
})
export class OptionsComponent implements AfterViewInit {
  @Input() label: string;
  @Input() labelClasses: string;
  @Input() areOptionsSelectable = false;
  @Input() items: any[];
  @Input() item: any;
  @Input() orientation: Orientation = DEFAULT_ORIENTATION;
  @Input() preferredOrientation: OrientationDirection = DEFAULT_ORIENTATION;
  @Output() selectOption = new EventEmitter<any>();

  @ViewChild('optionsMenu', {static: false})
  optionsMenu: ElementRef;

  @ViewChild('toggleButton', {static: false})
  toggleButton: ElementRef;

  menuOrientationClass: string;

  ngAfterViewInit(): void {
    let orientation = this.orientation;
    if (this.orientation === 'auto') {
      orientation = this.calculateOrientation(this.preferredOrientation);
    }
    this.menuOrientationClass = `options-menu-${orientation}`;
  }

  isSelected(item: SelectOption) {
    return (
      item &&
      this.item &&
      (item === this.item || item.value === this.item.value || item.values?.find((value: any) => value === this.item))
    );
  }

  get isLabelActive(): boolean {
    if (!this.areOptionsSelectable) {
      return;
    }
    return !!this.items.find(
      item =>
        item === this.item || item.value === this.item.value || item.values?.find((value: any) => value === this.item)
    );
  }

  onSelect(item: SelectOption) {
    if (!item.disabled && this.onSelect) {
      this.selectOption.emit(item);
    }
  }

  private calculateOrientation(preferredOrientation: OrientationDirection): Orientation {
    const el = this.optionsMenu.nativeElement;
    const originalCssProps: {visibility: string; display: string} = {
      visibility: el.style.visibility,
      display: el.style.display,
    };
    el.style.visibility = 'hidden';
    el.style.display = 'block';

    const width = el.offsetWidth;

    Object.entries(originalCssProps).forEach(([key, value]) => {
      el.style[key] = value;
    });

    const anchorRect = this.toggleButton.nativeElement.getBoundingClientRect();
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    const traverse = [
      preferredOrientation,
      ...ORIENTATION_TRAVERSE.filter(orientation => orientation !== preferredOrientation),
    ];

    const calcOrientation = traverse.find(orientation => {
      if (orientation === 'left') {
        return anchorRect.left + width <= viewportWidth;
      } else if (orientation === 'right') {
        return anchorRect.right - width >= 0;
      }

      return false;
    });

    return calcOrientation || preferredOrientation;
  }
}
