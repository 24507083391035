<div class="options">
  <a class="options-toggle" #toggleButton [ngClass]="{'active': toggle.active, 'selected': isLabelActive}" [class]="labelClasses" msToggle #toggle="toggle">{{ label }}</a>
  <ul class="options-menu" [ngClass]="menuOrientationClass" #optionsMenu [class.open]="toggle.active">
    <li class="options-menu-item"
        *ngFor="let item of items"
        [ngClass]="{ 'disabled': item.disabled, 'selected': isSelected(item) }"
        (click)="onSelect(item)">
      <a class="options-menu-anchor">{{ item.label | transloco }}</a>
    </li>
  </ul>
</div>
